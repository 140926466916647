<template>
  <div id="ClassDiagrams">
    <div class="w-100 d-inline-flex justify-content-between align-items-center mb-1">
      <h2>Class Diagrams</h2>
      <b-button-group>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-success"
          size="sm"
          data-cy="btn-cd-create"
          @click="openCreateModal"
        >
          <feather-icon icon="PlusIcon"/>
          Create Class Diagram
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-info"
          size="sm"
          @click="reloadAll"
        >
          <feather-icon icon="RefreshCwIcon"/>
        </b-button>
      </b-button-group>
    </div>

    <div>
      <class-diagram-edit-modal :form-data="{}" @onClose="onCreated"></class-diagram-edit-modal>
    </div>

    <div v-if="loading">
      <div class="w-100 d-inline-flex justify-content-center mt-4">
        <div class="d-flex flex-column align-items-center">
          <h5 class="text-warning mb-2">
            <atom-spinner class="animate-pulse"/>
          </h5>
          <h5>
            Loading Class Diagrams...
          </h5>
        </div>
      </div>
    </div>
    <div v-else class="w-100 d-flex justify-content-around">
      <b-card no-body class="w-100">
        <b-card-body>
          <div class="d-flex justify-content-between flex-wrap">

            <!-- Sorting  -->
            <b-form-group
              label="Sort"
              label-size="sm"
              label-align-sm="left"
              label-cols-sm="1"
              label-for="sortBySelect"
              label-class="mt-50 mr-50 pl-0"
              class="mb-0 text-nowrap"
              style="min-width: 20rem"
            >
              <b-input-group
                size="sm"
              >
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                >
                  <template #first>
                    <option value="">
                      none
                    </option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  :disabled="!sortBy"
                >
                  <option :value="false">
                    Asc
                  </option>
                  <option :value="true">
                    Desc
                  </option>
                </b-form-select>
              </b-input-group>
            </b-form-group>
            <!-- ./Sorting  -->

            <!-- Search -->
            <b-form-group
              label="Search"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
              label-for="filterInput"
              label-class="mt-50"
              class="mb-0"
              style="width: 30rem"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder=""
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <!-- ./Search -->
          </div>
        </b-card-body>

        <b-table
          responsive
          striped
          hover
          show-empty
          class="position-relative"
          :items="tableData"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          :per-page="perPage"
          :current-page="currentPage"
          @row-contextmenu="item => tempRowObject = item"
          @contextmenu.native.prevent="$refs.menu.open"
          @row-dblclicked="item => openDiagram(item)"
        >
          <template #cell(title)="{ item }">
            <div class="d-inline-flex">
              <span>{{ item.title }}</span>
              <b-badge v-if="item.metadata != null && item.metadata.default === true" variant="primary" class="ml-50">
                Default
              </b-badge>
            </div>
          </template>
          <template #cell(description)="{ item }">
            <span class="v-html-edit">{{ item.description }}</span>
          </template>
          <template #cell(node_count)="{ item }">
            <div v-if="item.node_count" class="d-flex flex-row justify-content-center">
              <b-badge :variant="item.node_count > 0 ? 'info' : 'secondary'">
                {{ item.node_count }}
              </b-badge>
            </div>
          </template>
          <template #cell(created)="{ item }">
        <span v-b-tooltip.hover.top.v-secondary.noninteractive="formatUpdatedTooltip(item)">
          {{ item.created | diffForHumans }}
        </span>
          </template>
          <template #cell(created_by)="{ item }">
        <span>
          {{ getUserUsername(item.created_by) }}
        </span>
          </template>
          <template #cell(status)="{ item }">
            <div v-if="item.status != null" class="d-flex flex-row justify-content-center">
              <b-badge :variant="determineVariant(item.status)">
                {{ item.status }}
              </b-badge>
            </div>
          </template>
          <template #cell(tag)="{ item }">
            <div v-if="item.tag != null && item.tag !== ''" class="d-flex flex-row justify-content-center">
              <b-badge variant="dark">
                {{ item.tag }}
              </b-badge>
            </div>
          </template>
          <template #cell(actions)="{ item }">
            <div class="d-inline-flex">
              <b-button
                v-b-tooltip.hover.top.noninteractive="'Open Class Diagram'"
                variant="flat-primary"
                class="py-0 btn-icon"
                @click="openDiagram(item)"
              >
                <feather-icon
                  icon="EditIcon"
                  size="16"
                  class="align-text-top"
                />
              </b-button>
            </div>

            <vue-context ref="menu" class="text-left">
              <li>
                <b-link
                  @click="openDiagram(tempRowObject)"
                >
                  <feather-icon
                    icon="EditIcon"
                    class="mr-75 font-small-3 text-primary"
                  />
                  <span class="text-primary">Open</span>
                </b-link>
              </li>
<!--              <li>-->
<!--                <b-link @click="$emit('showModalUpdate', tempRowObject)">-->
<!--                  <feather-icon icon="Edit3Icon" class="mr-25 mb-25"/>-->
<!--                  Properties-->
<!--                </b-link>-->
<!--              </li>-->
<!--              <li>-->
<!--                <b-link @click="$emit('showModalCopy', tempRowObject)">-->
<!--                  <feather-icon icon="CopyIcon" class="mr-25 mb-25"/>-->
<!--                  Copy-->
<!--                </b-link>-->
<!--              </li>-->
              <li>
                <b-link @click="deleteDiagram(tempRowObject)">
                  <feather-icon icon="TrashIcon" class="mr-25 mb-25 text-danger"/>
                  Delete
                </b-link>
              </li>
            </vue-context>
          </template>
        </b-table>

        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- Page length -->
          <b-form-group
            label="Per Page"
            label-cols="6"
            label-align="left"
            label-size="sm"
            label-class="mt-50"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
            />
          </b-form-group>
          <!-- ./Page length -->

          <!-- Pagination -->
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
          <!-- ./Pagination -->
        </b-card-body>
      </b-card>
    </div>

  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import AtomSpinner from '@/components/Spinners/AtomSpinner.vue'
import { BPagination, BTable, VBTooltip } from 'bootstrap-vue'
import { onMounted, ref } from '@vue/composition-api'
import VueContext from 'vue-context'
import coreService from '@/libs/api-services/core-service'
import { useRouter } from '@core/utils/utils'
import ClassDiagramEditModal from '@/views/DomainModel/ClassDiagrams/ClassDiagramEditModal.vue'
import store from '@/store'

export default {
  name: 'ClassDiagrams',
  components: {
    AtomSpinner,
    BTable,
    BPagination,
    ClassDiagramEditModal,
    VueContext,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  setup(props, context) {
    const fields = [
      {
        key: 'name', label: 'Name', sortable: true, tdClass: 'text-nowrap',
      },
      {
        key: 'node_count', label: 'Nodes', sortable: true,
      },
      {
        key: 'created', label: 'Created', sortable: true, tdClass: 'text-nowrap',
      },
      {
        key: 'created_by', label: 'Created By', sortable: true, tdClass: 'text-nowrap',
      },
      {
        key: 'actions', label: 'Actions', sortable: false, thClass: 'text-center', tdClass: 'text-center',
      },
    ]
    const perPage = 20
    const pageOptions = [10, 20, 50, 100]
    const totalRows = 1
    const currentPage = 1
    const sortBy = 'name'
    const sortDesc = false
    const sortDirection = 'asc'
    const filter = null
    const filterOn = []
    const tempRowObject = {}
    const { route, router } = useRouter()
    const tableData = ref([])
    const loading = ref(false)

    const sortOptions = fields
      .filter(f => f.sortable)
      .map(f => ({ text: f.label, value: f.key }))

    async function reloadAll() {
      const result = await coreService.classDiagramApi.getAll(route.value.params.modelId)
      tableData.value = result || []
    }

    onMounted(async () => {
      await reloadAll()
    })

    function onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      totalRows.value = filteredItems.length
      currentPage.value = 1
    }

    function formatUpdatedTooltip(item) {
      return `Created at ${(new Date(item.created)).toLocaleString()}`
    }

    function determineVariant(statusType) {
      if (statusType === 'Published') return 'success'
      if (statusType === 'Review') return 'warning'
      return 'secondary'
    }

    function openCreateModal() {
      context.root.$bvModal.show('modal__ClassDiagramUpdate')
    }

    function openDiagram(item) {
      store.commit('app/SET_DYNAMIC_PAGE_TITLE', item.name)
      router.push({ name: 'class_diagram', params: { diagramId: item.id } })
    }

    async function deleteDiagram(item) {
      const opts = {
        title: 'Delete Diagram',
        titleVariant: 'danger',
        centered: true,
        okVariant: 'danger',
        okTitle: 'Yes, Delete',
      }
      const result = await context.root.$bvModal.msgBoxConfirm(`This action is permanent and cannot be undone. Are you sure you want to delete the diagram '${item.name}'?`, opts)
      if (result) {
        await coreService.classDiagramApi.delete(item.id)
        await reloadAll()
      }
    }

    function onCreated(diagramId) {
      openDiagram({ id: diagramId, name: 'New' })
    }

    return {
      tableData,
      loading,
      reloadAll,
      openDiagram,
      deleteDiagram,
      openCreateModal,
      fields,
      perPage,
      pageOptions,
      totalRows,
      currentPage,
      sortBy,
      sortDesc,
      sortDirection,
      filter,
      filterOn,
      tempRowObject,
      sortOptions,
      onFiltered,
      formatUpdatedTooltip,
      determineVariant,
      onCreated,
    }
  },
}
</script>
<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-context.scss';

.v-html-edit {
  font-size: 0.9rem;

  p {
    margin: 0;
  }
}
</style>
