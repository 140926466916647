<template>
  <b-modal
    id="modal__ClassDiagramUpdate"
    title="Create Class Diagram"
    size="sm"
    :ok-title="loading ? 'Loading...' : 'Create Class Diagram'"
    :ok-disabled="loading"
    ok-variant="success"
    cancel-title="Discard"
    cancel-variant="outline-secondary"
    lazy
    no-fade
    @show="onShow"
    @close="onClose"
    @cancel="onClose"
    @ok.prevent="onSubmit"
  >
    <b-form-group
      label="Name"
      label-for="bt-name"
    >
      <b-form-input
        id="bt-name"
        v-model="formFields.name"
        placeholder="Class Diagram name..."
      />
    </b-form-group>

  </b-modal>
</template>
<script>
import { ref, toRef } from '@vue/composition-api'
import coreService from '@/libs/api-services/core-service'
import { useRouter } from '@core/utils/utils'

export default {
  name: 'UpdateClassDiagramModal',
  props: {
    formData: {
      type: Object,
    },
  },
  setup(props, context) {
    const loading = ref(false)
    const formFields = ref({})
    const { route, router } = useRouter()

    const onShow = () => {
      formFields.value = {
        name: '', description: '', status: '', tag: '',
      }
    }
    const onClose = () => {
      formFields.value = {
        name: '', description: '', status: '', tag: '',
      }
      context.emit('onClose', null)
    }
    const onSubmit = async () => {
      loading.value = true
      let diagramId = null
      try {
        diagramId = await coreService.classDiagramApi.create({ name: formFields.value.name, model_id: route.value.params.modelId })
      } catch (e) {
        console.error(e)
      } finally {
        context.root.$bvModal.hide('modal__ClassDiagramUpdate')
        loading.value = false
        context.emit('onClose', diagramId)
      }
    }

    return {
      loading,
      formFields,
      onShow,
      onClose,
      onSubmit,
    }
  },
}
</script>
